<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}" @click.self="closeModal">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Form <span>Form Analytics</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <div class="section_wpr">
                        <h3>{{ activeForm.survey_name }}</h3>
                    </div>
                    <div class="analytics_loader_area" v-if="loader"><quote-loader/></div>
                    <template v-else>
                        <div v-if="!hasAgreementWarning">
                            <ol class="ques_listing" v-if="analytics.length">
                                <li v-for="(analytic, a) in analytics" :key="a" class="responses">
                                    <label class="q_index">{{a + 1}}</label>
                                    <p>
                                        <span v-if="analytic.question != null" v-html="analytic.question.replace(/<(?:.|\n)*?>/gm, '')"></span>
                                        <span v-if="analytic.answer_required_yn == 1" class="text-danger">*</span><br />
                                        <small>{{ analytic.sub_text }}</small>
                                    </p>
                                    <table v-if="analytic.input_type_id == 1 || analytic.input_type_id == 2 || analytic.input_type_id == 4">
                                         <tbody>
                                             <tr v-for="(option, o) in analytic.options" :key="o">
                                                 <td>{{option.option}}</td>
                                                 <td>{{option.count}}</td>
                                             </tr>
                                         </tbody>
                                     </table>
                                     <table v-else>
                                         <tbody v-if="analytic.options.length > 0">
                                             <tr  v-for="(option, o) in analytic.options[0].answer" :key="o">
                                                 <td class="text-left"><span v-html="option.answer_text"></span></td>
                                                 <td></td>
                                             </tr>
                                         </tbody>
                                     </table>
                                </li>
                            </ol>
                            <p v-else>No Questions</p>
                        </div>
                        <p class="agreement_warning" v-else>Because of the large text quantity in agreement-related questions, analytics is unavailable for this question type.</p>
                    </template>
                </div>
            </div>
            <div class="modal_footer">
                <button type="button" :disabled="downloadAnalyticsLoader" class="btn cancel_btn" @click="closeModal">Cancel</button>
                <button type="button" :disabled="downloadAnalyticsLoader" class="btn save_btn" @click="handleGenerateReport" v-if="!hasAgreementWarning">
                    <i class="fa fa-spin fa-spinner" v-if="downloadAnalyticsLoader"></i> &nbsp;{{ downloadAnalyticsLoader ? 'Generating' : 'Generate PDF' }}
                </button>
                <button type="button" :disabled="downloadAnalyticsCsvLoader" class="btn save_btn" @click="handleGenerateCsvReport" v-if="!hasAgreementWarning">
                    <i class="fa fa-spin fa-spinner" v-if="downloadAnalyticsCsvLoader"></i> &nbsp;{{ downloadAnalyticsCsvLoader ? 'Generating' : 'Generate CSV' }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    import Toastr from '@/utils/Toastr'

    export default {
        name: 'Form Show Analytic',

        data () {
            return {
                hasAgreementWarning: false,
            }
        },

        props: {
            modelValue: Boolean,
            activeForm: Object,
        },

        emit: ['update:modelValue'],

        watch: {
            modelValue (value) {
                const vm = this;

                if (value) {
                    const params = {
                        survey_id: vm.activeForm.id,
                        title: vm.activeForm.survey_name,
                    };

                    vm.getAnalytics(params);
                }
            },

            analytics (analytics) {
                const vm = this;

                if (analytics.length == 1 && analytics[0]['input_type_id'] == 7) {
                    vm.hasAgreementWarning = true;
                } else {
                    vm.hasAgreementWarning = false;
                }
            },
        },

        computed: mapState({
            loader: state => state.formModule.formComponentLoader,
            analytics: state => state.formModule.analytics,
            downloadAnalyticsLoader: state => state.formModule.downloadAnalyticsLoader,
            downloadAnalyticsCsvLoader: state => state.formModule.downloadAnalyticsCsvLoader,
        }),

        methods: {
            ...mapActions({
                getAnalytics: 'formModule/getAnalytics',
                downloadAnalytics: 'formModule/downloadAnalytics',
                downloadAnalyticsCsv: 'formModule/downloadAnalyticsCsv'
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            handleGenerateReport () {
                const vm = this;

                const params = {
                    survey_name: vm.activeForm.survey_name,
                    survey_id: vm.activeForm.id
                };

                vm.downloadAnalytics(params);
            },

            handleGenerateCsvReport () {
                const vm = this;

                const params = {
                    survey_name: vm.activeForm.survey_name,
                    survey_id: vm.activeForm.id
                };

                vm.downloadAnalyticsCsv(params);
            },
        }
    }
</script>
<style scoped>
    .ques_listing li {
        font-size: 14px;
        line-height: 19px;
        padding: 15px 15px 15px 35px;
        border: 1px solid #e9e9e9;
        border-radius: 10px;
        margin-bottom: 15px;
        position: relative;
        background: #fff;
    }
    .ques_listing li p{
        font-weight: 500;
    }
    .ques_listing li table{
        width: 100%;
        margin-top: 7px;
    }
    .ques_listing li table tr td{
        font-size: 13px;
        padding: 2px 0;
        color: #5a5a5a;
    }
    .ques_listing li table tr td:last-child {
        text-align: right;
    }
    .ques_listing .q_index{
        position: absolute;
        left: 10px;
        top: 18px;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #999;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 9px;
    }

    .agreement_warning {
        font-size: 15px;
        font-weight: 300;
        margin-top: 15px;
    }

    .analytics_loader_area {
        height: 50vh;
        display: flex;
        align-items: center;
        align-content: center;
        justify-content: center;
    }

    td.text-left {
        text-align: left;
    }

    li.responses table tr td {
        border-bottom: 1px solid #eaeaea;
    }

    li.responses table tr:last-child td {
        border-bottom: 0px;
    }
</style>
